import React from "react";
import "./download.css";
import { Container, Row, Image, Button, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import appstore from "../../assets/images/icon/app-st.png";
import playstore from "../../assets/images/icon/play-st.png";
import phone from "../../assets/images/Phone.png";
import { useClassToggle } from "../context";
const Download = () => {
  const { isToggled, toggleClass } = useClassToggle();
  return (
    <>
      <section
        className={`back-color-white download-apap ${
          isToggled ? "dark" : "light"
        }`}
      >
        <Container>
          <Row>
            <Col lg-="12" className="text-end">
              <small className="text-color-gray">Download Xturra</small>
              <h2 className="text-color-gray subtitle">
                Get it <span>to your smartphone</span>
              </h2>
            </Col>
          </Row>
          <Row>
            <Col lg="5" className="text-center">
              <p className="text-color-gray">Now available on...</p>
              {/* <Link to="/">
                {" "}
                <Image src={appstore} alt="" />
              </Link> */}
              {/* <Link o="/">  <Image src={playstore} alt="" /></Link> */}
              <a
                href="https://apps.apple.com/in/app/xturra-client/id1669205836"
                target="_blank"
              >
                <Image src={appstore} alt="" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.xturra.client"
                target="_blank"
              >
                <Image src={playstore} alt="" />
              </a>
            </Col>
            <Col lg="7" className="text-center phone-demo">
              <Image src={phone} alt="" />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Download;
