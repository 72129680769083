import React, { useEffect, useState } from "react";
import { Container, Row, Image, Button, Col, Accordion, Tab, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getAllFaqClientList } from "../../../Service/Faq/FaqManagementService";
const TopicsClients = () => {


    const [faqClientList, setFaqClientList] = useState([]);

    useEffect(() => {
        getAllFaqClient();
    }, [])

    async function getAllFaqClient() {
        const response = await getAllFaqClientList();
        setFaqClientList(response?.data?.result);

    }

    return (
        <>
            {faqClientList.map((item, index) => (
                <>
                    <Row>
                        <Col lg="12">
                            <h3 className="text-color-gray ">
                                {item.feature}
                            </h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="1"></Col>
                        <Col lg="10">
                            <Accordion defaultActiveKey="0" flush >
                                {item.faqs.map((faq, index) => (
                                    <Accordion.Item eventKey={index.toString()} key={index}>
                                        <Accordion.Header>{faq.question}</Accordion.Header>
                                        <Accordion.Body>
                                            <p>
                                                {faq.answer}
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </Col>
                    </Row>

                </>

            ))}
        </>
    );
};

export default TopicsClients;