import React, { useEffect, useState } from "react";
import { Container, Row, Image, Button, Col, Accordion, Tab, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getAllFaqVendorList } from "../../../Service/Faq/FaqManagementService";
const TopicsVendors = () => {

    const [faqVendorList, setFaqVendorList] = useState([]);

    useEffect(() => {
        getAllFaqVendor();
    }, [])

    async function getAllFaqVendor() {
        const response = await getAllFaqVendorList();
        setFaqVendorList(response?.data?.result);
    }
    console.log("vendor" ,faqVendorList)

	return (
		<>
            {faqVendorList.map((item, index) =>(
                <>
                    <Row>
                        <Col lg="12">
                            <h3 className="text-color-gray ">
                                {item.feature}
                            </h3>
                        </Col>
                    </Row>
                    <Row>
                    <Col lg="1"></Col>
                        <Col lg="10">
                            <Accordion defaultActiveKey="0" flush >
                                {item.faqs.map((faq, index) =>(
                                    <Accordion.Item eventKey={index.toString()} key={index}>
                                        <Accordion.Header>{faq.question}</Accordion.Header>
                                        <Accordion.Body>
                                            <p>
                                                {faq.answer}
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </Col>
                    </Row>
                    
                </>
                
            ))}
        </>
	);
};

export default TopicsVendors;