import React, { useState, useEffect } from 'react';
import './nave.css';
import close from '../../assets/images/icon/close.png';
import theme from '../../assets/images/icon/theme.png';
import { Container, Row, Image, Button, Col, Modal, } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { useClassToggle } from '../../components/context';
const Navemenu = ({ navshow, setNavshow }) => {
    const handleClose = () => {
        setNavshow(false);
    };

    const [isActive, setIsActive] = useState(true);

    const submenuhandleClick = () => {
        setIsActive(!isActive); // Toggle the isActive state
    };
    const { isToggled, toggleClass } = useClassToggle();
    return (

        <>
            <Modal
                show={navshow}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className='mian-menu-top'
            >
                <Modal.Body className='py-5'>
                    <Button variant="secondary" onClick={handleClose}>
                        <Image src={close} alt="" />
                    </Button>
                    <Container>
                        <Row>
                            <Col lg="12" className='py-5'>
                                <ul>
                                    <li>
                                        <span>1</span>   <Link to="/services" onClick={handleClose} >
                                            Service
                                        </Link>
                                    </li>
                                    <li>
                                        <span>2</span> <Link to="/product" onClick={handleClose}  >
                                            Products
                                        </Link>
                                    </li>
                                    <li>
                                        <span>3</span> <a href='javascript:void(0)' className='dropdown' onClick={submenuhandleClick}>Resources</a>
                                        <ul className={isActive ? 'd-none' : ''}>
                                            <li >
                                                <Link to="/blog" onClick={() => { handleClose(); submenuhandleClick(); }} >
                                                    Blog
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/help-center" onClick={() => { handleClose(); submenuhandleClick(); }}>
                                                    Help Center & Contact
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li onClick={handleClose}>
                                        <span></span>  <a onClick={toggleClass} href='javascript:void(0)' ><Image className='tm-img' src={theme} alt="" /> {isToggled ? 'Select light theme' : 'Select dark theme'}</a>

                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>

            </Modal>
        </>
    );
};

export default Navemenu;