import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Container, Nav, Tab, Image } from 'react-bootstrap';
import './homeblog.css';
import blog1 from '../../../assets/images/blog/blog1.png';
import blog2 from '../../../assets/images/blog/blog2.png';
import blog3 from '../../../assets/images/blog/blog3.png';
import arrwo from '../../../assets/images/icon/arrow-right.svg';
import { Link } from 'react-router-dom';
import { useClassToggle } from '../../context';
import { getAllBlogList } from '../../../Service/Blog/BlogManagementService';
import { format } from 'date-fns';

const HomeBlog = () => {
    const { isToggled, toggleClass } = useClassToggle();
    const [blogList, setBlogList] = useState([]);

    useEffect(() => {
        getAllBlog()
    }, [])

    async function getAllBlog() {
        const response = await getAllBlogList();
        const blogs = response?.data?.result?.data.slice(0, 3);
        setBlogList(blogs);
        // console.log("blog", response?.data?.result?.data)
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        return format(date, "dd MMM, yyyy");
    }
    return (

        <>
            <section className={`pt-100 homeblog  back-color-white ${isToggled ? 'dark' : 'light'}`}>
                <span className='scroll-down'>Scroll Down</span>
                <Container>
                    <Row>
                        <Col lg="12 text-center">
                            <h3 className='blog-title text-color-gray'>Blog</h3>
                            <p className='text-color-gray'>
                                Our transportation app is designed to be flexible and adaptable, to meet the unique needs of each individual user or enterprise.
                            </p>
                            <Link to='/blog'>
                                <Button className='blog-btn'>Read more</Button>
                            </Link>
                        </Col>
                        <Col lg="12">
                            {
                                blogList.length > 0 ? (
                                    <div className='blog-item-list'>
                                        {
                                            blogList.map((blog, index) => (
                                                <div className='blog-item' key={index}>
                                                    <Image src={blog?.image_url} alt="" />
                                                    <div className='blog-info'>
                                                        <h5>
                                                            {blog?.title}
                                                        </h5>
                                                        <p>
                                                            <small>{formatDate(blog?.created_at)}</small>
                                                        </p>
                                                        <Link className='betails' to='blog-details' state={blog}><Image alt="" src={arrwo} /></Link>
                                                    </div>
                                                </div>

                                            ))}
                                        {/* <div className='blog-item active'>
                                    <Image src={blog2} alt="" />
                                    <div className='blog-info'>
                                        <h5>
                                            Lorem Ipsum is simply
                                        </h5>
                                        <p>
                                            <small>23 Feb, 2023</small> <small>By, John Doe</small>
                                        </p>
                                        <Link className='betails' to='blog/1'><Image alt="" src={arrwo} /></Link>
                                    </div>
                                </div>
                                <div className='blog-item'>
                                    <Image src={blog3} alt="" />
                                    <div className='blog-info'>
                                        <h5>
                                            Lorem Ipsum is simply
                                        </h5>
                                        <p>
                                            <small>23 Feb, 2023</small> <small>By, John Doe</small>
                                        </p>
                                        <Link className='betails' to='blog/1'><Image alt="" src={arrwo} /></Link>
                                    </div>
                                </div> */}
                                    </div>
                                )
                                    :
                                    (
                                        <p>No Blogs Found</p>
                                    )}
                        </Col>
                    </Row>
                </Container>
            </section>
        </>

    );
};

export default HomeBlog;