import React from "react";
import './innerbreadcrumb.css';
import { Container, Row, Image, Button, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
const Innerbreadcrumb = (props) => {
    const classes = 'Innerbreadcrumb' + props.className;
	return (
		<section className={classes}> 
            <Container>
                <Row>
                    <Col lg="12">
                        <ul>
                            <li>
                                <Link to="/">
                                    Home
                                </Link>
                            </li>
                            <li>
                            /
                            </li>
                            <li>
                            {props.children}
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
		</section>
	);
};

export default Innerbreadcrumb;