import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import icon from '../../assets/images/icon/sr.svg';
import icon1 from '../../assets/images/icon/sr1.svg';
import icon2 from '../../assets/images/icon/sr2.svg';
import icon3 from '../../assets/images/icon/sr3.svg';
import icon4 from '../../assets/images/icon/sr4.svg';
import ServicemainContent from "./service-main-content";
import ServiceList from "./service-list";
import './services.css'
import { useClassToggle } from "../context";
const ServicesInfo = ()=>{
    const { isToggled, toggleClass } = useClassToggle();
    const servicesinfodetails = [
        {
            heading: 'How Does',
            title: 'Transport Delivery App Work?',
            Description: `<p>Xturra is a sophisticated logistics solution that simplifies the complex process of moving goods from one place to another. At its core, Xturra functions as a bridge between clients in need of transportation services and professional transporters ready to fulfill those needs. Clients can use the app to submit their transportation requests, providing essential details such as pickup and delivery locations, cargo specifics, and any additional requirements. Transporters and carriers, on the other hand, receive and respond to these requests, offering competitive quotes and committing to the job. Xturra’s real-time tracking feature allows clients to monitor the progress of their shipments, ensuring transparency and peace of mind throughout the journey. </p>
            <p>Payment processing is secure and seamless, facilitated through trusted gateways, such as Stripe. In essence, Xturra streamlines the entire logistics process, making it efficient, accessible, and reliable for all parties involved, from clients to transporters.</p>`,
            Services:[
                {
                    serviceicon: icon,
                    title:'Login/Signup',
                    // info:'There are many variations of passages of Lorem Ipsum.'
                },
                {
                    serviceicon: icon1,
                    title:'Make a Request',
                    // info:'There are many variations of passages of Lorem Ipsum.'
                },
                {
                    serviceicon: icon2,
                    title:'Payment Option',
                    // info:'There are many variations of passages of Lorem Ipsum.'
                },
                {
                    serviceicon: icon3,
                    title:'Confirm Vendor',
                    // info:'There are many variations of passages of Lorem Ipsum.'
                },
                {
                    serviceicon: icon4,
                    title:'Track Courier',
                    // info:'There are many variations of passages of Lorem Ipsum.'
                },
                {
                    serviceicon: icon,
                    title:'Ratings & Reviews',
                    // info:'There are many variations of passages of Lorem Ipsum.'
                },
            ]
        }
    ];
    return(
        <section className={`back-color-white servicespageinfo ${isToggled ? 'dark' : 'light'}`}>
            <Container>
                <Row>
                    <Col lg="6">
                        <ServicemainContent serviceContent={servicesinfodetails} />
                    </Col>
                    <Col lg="6">
                        <ServiceList serviceList={servicesinfodetails} />
                    </Col>
                </Row>
            </Container>
        </section>
    )
} 

export default ServicesInfo;