import React, { useState ,useEffect} from "react";
import './homefaq.css';
import { Container, Row, Image, Button, Col, Accordion  } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useClassToggle } from "../../context";
import {getFaqList} from './homefaqService';
const HomeFaq = () => {
    const { isToggled, toggleClass } = useClassToggle();
    const [faqList, setFaqList] = React.useState([]);

    useEffect(() => {
        getallFaqList()
      }, []);

      async function getallFaqList() {
        getFaqList().then(function (result) {
          const response = result.data;
          console.log(response, "response")
          setFaqList(response.result.data);
        });
      }

      console.log(faqList,"faqList")


    const data = [
        {
            title:'Are there any additional fees for using the service ?',
            description:`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,`
        },
        {
            title:'Can I choose the carrier for my shipment ?',
            description:`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,`
        },
        {
            title:'Can I choose the carrier for my shipment ?',
            description:`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,`
        },
        {
            title:'How can I trust my shipment ?',
            description:`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,`
        },
    ]
	return (
		<>
            <section className={`back-color-white homefag-min ${isToggled ? 'dark' : 'light'}`}>
                <Container>
                    <Row>
                        <Col>
                            <h2 className="subtitle text-color-gray mb-3">
                                <span>FAQ</span>
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="2">

                        </Col>
                        <Col lg="10" className="faq-accordion">
                            <Accordion defaultActiveKey="0" flush>
                                {faqList.map((item, index) =>(
                                    <Accordion.Item eventKey={index.toString()} key={index}>
                                        <Accordion.Header>{index+1} {item.title}</Accordion.Header>
                                        <Accordion.Body>
                                            <p>
                                                {item.description}
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
	);
};

export default HomeFaq;