import React from "react";
import { Col, Container, Row, Image, Card } from "react-bootstrap";
import banner from "../../../assets/images/blog/blog-banne.png";
import blog1 from "../../../assets/images/blog/blog17.png";
import blog2 from "../../../assets/images/blog/blog16.png";
import blog3 from "../../../assets/images/blog/blog4.png";
import blog5 from "../../../assets/images/blog/blog15.png";
import blog6 from "../../../assets/images/blog/blog5.png";
import blog7 from "../../../assets/images/blog/blog6.png";
import blog8 from "../../../assets/images/blog/blog7.png";
import blog9 from "../../../assets/images/blog/blog8.png";
import blog10 from "../../../assets/images/blog/blog9.png";
import blog11 from "../../../assets/images/blog/blog4.png";
import blog13 from "../../../assets/images/blog/blog11.png";
import blog12 from "../../../assets/images/blog/blog12.png";
import profile from "../../../assets/images/profile.png";
import GetinTouch from "../../../components/getintouch";
import { useClassToggle } from "../../../components/context";
import { useLocation } from "react-router-dom";
import moment from 'moment';

const BlogDetails = () => {
	const { isToggled, toggleClass } = useClassToggle();
	const location = useLocation();
    const blog_details = location.state;

	  // Function to split content into paragraphs and render individually
	  function renderContent(content) {
		if (!content) return <h2 className='text-center'>No Blog Content Found</h2>;
		
		const paragraphs = content.split('<p>').map((paragraph, index) => (
			<div key={index}>
				<p dangerouslySetInnerHTML={{ __html: paragraph.replace('</p>', '') }} />
			</div>
		));

		return paragraphs;
	}


	return (
		<>
			<section className="background-color-gray blogdetails-banner pb-5">
				<Container>
					<Row>
						<Col lg="12" className="blog-banner">
							<Image className="blog-banner-img w-100" src={blog_details?.image_url} alt="" />
						</Col>
					</Row>
				</Container>
			</section>
			<section className={`back-color-white blogdetails ${isToggled ? 'dark' : 'light'}`}>
				<Container>
					<Row>
						<Col lg="12">
							<div className="blog-detais-info text-color-gray pb-3 mb-3">
								<Row className="mb-3">
									<Col lg="6">
										<h2 className="blog-title">{blog_details?.title}</h2>
									</Col>
									<Col lg="6" className="text-end">
										<div className="profile-info">
											<div className="profile-info-wrap">
												<div className="profile-pic">
													<Image alt="profile" src={profile} className="profille-image" />
												</div>
												<div className="profile-info text-color-gray">
													<p> <span className="ps-3">{moment(blog_details?.created_at).format("DD MMM, YYYY")}</span></p>
												</div>
											</div>

										</div>
									</Col>
								</Row>
								<Row>
									<Col lg="12">
										<p>
										{renderContent(blog_details?.content)}
										</p>
										{/* <p>
											laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. perspiciatis unde omnis iste natus error sit voluptatem accusantium loremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
										</p> */}
										{/* <h3>Middle Post Heading</h3>
										<p>
											laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. perspiciatis unde omnis iste natus error sit voluptatem accusantium loremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
										</p> */}
									</Col>
								</Row>
								{/* <Row>
									<Col lg="7">
										<p>
											laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. perspiciatis unde omnis iste natus error sit voluptatem accusantium loremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
										</p>
										<p>
											laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. perspiciatis unde omnis iste natus error sit voluptatem accusantium loremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
										</p>
										<p>
											laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. perspiciatis unde omnis iste natus error sit voluptatem accusantium loremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
										</p>
									</Col>
									<Col lg="5" className="news-right">
										<Card className="news-list mb-3">
											<div className="news-list-img">
												<Image src={blog11} alt="" />
											</div>
											<div className="news-contain ps-3">
												<h3>
													Lorem Ipsum simply dummy text of the printing industry.
												</h3>
												<p className="oth-date">
													<span>23 <b>Feb, 2023</b></span> <span>By, John Doe</span>
												</p>
											</div>
										</Card>
										<Card className="news-list mb-3">
											<div className="news-list-img">
												<Image src={blog12} alt="" />
											</div>
											<div className="news-contain ps-3">
												<h3>
													Lorem Ipsum simply dummy text of the printing industry.
												</h3>
												<p className="oth-date">
													<span>23 <b>Feb, 2023</b></span> <span>By, John Doe</span>
												</p>
											</div>
										</Card>
										<Card className="news-list mb-3">
											<div className="news-list-img">
												<Image src={blog13} alt="" />
											</div>
											<div className="news-contain ps-3">

												<h3>
													Lorem Ipsum simply dummy text of the printing industry.
												</h3>
												<p className="oth-date">
													<span>23 <b>Feb, 2023</b></span> <span>By, John Doe</span>
												</p>
											</div>
										</Card>
									</Col>
									
								</Row> */}
								{/* <Row>
									<Col lg="12">
										<p>
											labore. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt
										</p>
										<p>
											Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architect.
										</p>
									</Col>
								</Row>
								<Row>
									<Col lg="6">
										<p>
											laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. perspiciatis unde omnis iste natus error sit voluptatem accusantium loremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
											laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. perspiciatis unde omnis iste natus error sit voluptatem accusantium loremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo
										</p>
									</Col>
									<Col lg="6">

									</Col>
								</Row>
								<Row>
									<Col lg="12">
										<p>
											labore. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt
										</p>
										<p>
											Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architect.
										</p>
									</Col>
								</Row> */}
							</div>
						</Col>
					</Row>
					{/* <Row>
						<Col lg="12">
							<h2 className="subtitle text-color-gray pb-5">Related blog post</h2>
						</Col>
					</Row> */}
					{/* <Row>
						<Col lg="3">
                            <Card className="blog-item mb-3" style={{ backgroundImage: `url(${blog1})` }}>
                                <div className="blog-item-info">
                                    <h3>
                                    Lorem Ipsum is simply
                                    </h3>
                                    <p  className="oth-date">
                                        <span>23 <b>Feb, 2023</b></span> <span>By, John Doe</span>
                                    </p>
                                </div>
                            </Card>
						</Col>
						<Col lg="3">
                            <Card className="blog-item mb-3" style={{ backgroundImage: `url(${blog2})` }}>
                                <div className="blog-item-info">
                                    <h3>
                                    Lorem Ipsum is simply
                                    </h3>
                                    <p  className="oth-date">
                                        <span>23 <b>Feb, 2023</b></span> <span>By, John Doe</span>
                                    </p>
                                </div>
                            </Card>
						</Col>
						<Col lg="3">
                            <Card className="blog-item mb-3" style={{ backgroundImage: `url(${blog3})` }}>
                                <div className="blog-item-info">
                                    <h3>
                                    Lorem Ipsum is simply
                                    </h3>
                                    <p  className="oth-date">
                                        <span>23 <b>Feb, 2023</b></span> <span>By, John Doe</span>
                                    </p>
                                </div>
                            </Card>
						</Col>
						<Col lg="3">
                            <Card className="blog-item mb-3" style={{ backgroundImage: `url(${blog2})` }}>
                                <div className="blog-item-info">
                                    <h3>
                                    Lorem Ipsum is simply
                                    </h3>
                                    <p  className="oth-date">
                                        <span>23 <b>Feb, 2023</b></span> <span>By, John Doe</span>
                                    </p>
                                </div>
                            </Card>
						</Col>
					</Row> */}
				</Container>
			</section>
			<GetinTouch className="gt-blog-details"></GetinTouch>
		</>
	);
};

export default BlogDetails;