import React from "react";
import InnerBanner from "../../components/innerpagebanner";
import innerbannerimg from "../../assets/images/banner/inner-banner.jpg";
import Innerbreadcrumb from "../../components/innerbreadcrumb";
import './privacy-policy.css';
import PrivacyPolicyConten from "./privacy-policy-conten";
const PrivacyPolicy = () => {
	return (
		<>
		<InnerBanner style={{ backgroundImage: `url(${innerbannerimg})` }}>
            <span>Privacy </span>   Policy
        </InnerBanner>
		<Innerbreadcrumb >Privacy Policy</Innerbreadcrumb>
		<PrivacyPolicyConten />
		</>
	);
};

export default PrivacyPolicy;