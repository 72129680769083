import React from "react";
import InnerBanner from "../../components/innerpagebanner";
import innerbannerimg from "../../assets/images/banner/inner-banner.jpg";
import Innerbreadcrumb from "../../components/innerbreadcrumb";
import './policy-transportable.css';
import PolicyTransportableConten from "./policy-transportable-conten";
const PolicyTransportable = () => {
	return (
		<>
		<InnerBanner style={{ backgroundImage: `url(${innerbannerimg})` }}>
            <span>Policy </span> on Transportable Goods and Items
        </InnerBanner>
		<Innerbreadcrumb >Policy on Transportable Goods and Items</Innerbreadcrumb>
		<PolicyTransportableConten />
		</>
	);
};

export default PolicyTransportable;