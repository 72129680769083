import React, { useState, useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Navigate,
} from "react-router-dom";
import "./App.css";
import "./assets/css/global.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Col, Row, Container } from "react-bootstrap";
import Layout from "./layout/layout";
import Home from "./pages/home";
import Blog from "./pages/blog";
import BlogDetails from "./pages/blog/blog-details";
import Services from "./pages/services";
import Product from "./pages/product";
import HelpCenter from "./pages/help-center";
import Contact from "./pages/contact";
import PrivacyPolicy from "./pages/privacy-policy";
import TermCondition from "./pages/term-condition";
import About from "./pages/about";
import Illegal from "./pages/illegal";
import ProhibitionRecreational from "./pages/prohibition-recreational";
import AppropriateLanguage from "./pages/appropriate-language";
import RefundPolicy from "./pages/refund-policy";
import PolicyService from "./pages/policy-service";
import PolicyLost from "./pages/policy-lost";
import Error from "./pages/error";
import { ClassToggleProvider } from "./components/context";
import ScrollToTop from "./components/scrolltotop";
import PolicyTransportable from "./pages/policy-transportable";
import AntiMoney from "./pages/anti-money";
import ManualHandling from "./pages/manual-handling";
import Safety from "./pages/safety";
function App() {
  return (
    <ClassToggleProvider>
      <ScrollToTop />
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog-details" element={<BlogDetails />} />
          <Route path="/services" element={<Services />} />
          <Route path="/product" element={<Product />} />
          <Route path="/help-center" element={<HelpCenter />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermCondition />} />
          <Route path="/about" element={<About />} />
          <Route
            path="/illegal-prohibited-and-restricted-cargo"
            element={<Illegal />}
          />
          <Route
            path="/prohibition-of-recreational-substances-manual"
            element={<ProhibitionRecreational />}
          />
          <Route
            path="/appropriate-language-and-attire-manual"
            element={<AppropriateLanguage />}
          />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/policy-of-service" element={<PolicyService />} />
          <Route
            path="/policy-for-lost-or-damaged-items-or-goods"
            element={<PolicyLost />}
          />
          <Route
            path="/policy-on-transportable-goods-and-items"
            element={<PolicyTransportable />}
          />
          <Route
            path="/anti-money-laundering-and-terrorist-financing-policy"
            element={<AntiMoney />}
          />
          <Route
            path="/manual-for-handling-client-complaints-and-conflict-resolution"
            element={<ManualHandling />}
          />
          <Route path="/safety-and-ohs-manual" element={<Safety />} />
          <Route path="/*" element={<Error />} />
        </Routes>
      </Layout>
    </ClassToggleProvider>
  );
}

export default App;
