import React from "react";
import InnerBanner from "../../components/innerpagebanner";
import innerbannerimg from "../../assets/images/banner/inner-banner.jpg";
import Innerbreadcrumb from "../../components/innerbreadcrumb";
import './prohibition-recreational.css';
import ProhibitionRecreationalConten from "./prohibition-recreational-conten";
const ProhibitionRecreational = () => {
	return (
		<>
		<InnerBanner style={{ backgroundImage: `url(${innerbannerimg})` }}>
            <span>-Prohibition  </span>  of Recreational Substances Manual
        </InnerBanner>
		<Innerbreadcrumb >Prohibition of Recreational Substances Manual</Innerbreadcrumb>
		<ProhibitionRecreationalConten />
		</>
	);
};

export default ProhibitionRecreational;