import React from "react";
import InnerBanner from "../../components/innerpagebanner";
import innerbannerimg from "../../assets/images/banner/inner-banner.jpg";
import Innerbreadcrumb from "../../components/innerbreadcrumb";
import './anti-money.css';
import AntiMoneyConten from "./anti-money-conten";
const AntiMoney = () => {
	return (
		<>
		<InnerBanner style={{ backgroundImage: `url(${innerbannerimg})` }}>
            <span>Anti-Money </span> Laundering and Terrorist Financing Policy
        </InnerBanner>
		<Innerbreadcrumb >Anti-Money Laundering and Terrorist Financing Policy</Innerbreadcrumb>
		<AntiMoneyConten />
		</>
	);
};

export default AntiMoney;