import React, { useState } from 'react';
import asimg1 from '../../../assets/images/asimg1.png';
import asimg2 from '../../../assets/images/asimg2.png';
import asimg3 from '../../../assets/images/asimg3.png';
import { Button, Row, Col, Container, Nav, Tab, Image } from 'react-bootstrap';
const Vendors = () => {
    
	return (
        
       <>
           <section className='appService-galleryimg'>
                <div className='asglist'>
                    <Image src={asimg1} alt="" />
                    <h4>Carriers</h4>
                </div>
                <div className='asglist'>
                    <Image src={asimg2} alt="" />
                    <h4>Freight</h4>
                </div>
                <div className='asglist'>
                    <Image src={asimg3} alt="" />
                    <h4>Logistics</h4>
                </div>
           </section>
       </>

	);
};

export default Vendors;