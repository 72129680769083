import React from "react";
import InnerBanner from "../../components/innerpagebanner";
import innerbannerimg from "../../assets/images/banner/inner-banner.jpg";
import Innerbreadcrumb from "../../components/innerbreadcrumb";
import AppService from "../../components/appservice";
import GetinTouch from "../../components/getintouch";
import ServicesInfo from "../../components/services";
import './services.css'
const Services = () => {
	return (
		<>
			<InnerBanner style={{ backgroundImage: `url(${innerbannerimg})` }}>
            <span>The </span>   Services
        </InnerBanner>
		<Innerbreadcrumb >Services</Innerbreadcrumb>
		<ServicesInfo />
		<AppService className="Servicesage" />
		<GetinTouch className="Servicesagegetintuch"  />
		
		</>
	);
};

export default Services;