import React from "react";
import InnerBanner from "../../components/innerpagebanner";
import innerbannerimg from "../../assets/images/banner/help-center-banner.jpg";
import Innerbreadcrumb from "../../components/innerbreadcrumb";
import Faq from "../../components/faq";
import RequestForm from "../../components/requestform";
// import GetinTouch from "../../components/getintouch";

const HelpCenter = () => {
	return (
		<>
			<InnerBanner style={{ backgroundImage: `url(${innerbannerimg})` }}>
                <span>Help </span>  Center
            </InnerBanner>
			<Innerbreadcrumb >Help Center</Innerbreadcrumb>
			<Faq />
			<RequestForm className="modffrom_section"/>
			{/* <GetinTouch className="gt-blog-details"></GetinTouch> */}

		</>
	);
};

export default HelpCenter;