import React from "react";
import InnerBanner from "../../components/innerpagebanner";
import innerbannerimg from "../../assets/images/banner/inner-banner.jpg";
import Innerbreadcrumb from "../../components/innerbreadcrumb";
import './term-condition.css';
import TermConditionConten from "./term-condition-conten";
const TermCondition = () => {
	return (
		<>
		<InnerBanner style={{ backgroundImage: `url(${innerbannerimg})` }}>
            <span>Terms</span> and Conditions
        </InnerBanner>
		<Innerbreadcrumb >Terms & Conditions</Innerbreadcrumb>
		<TermConditionConten />
		</>
	);
};

export default TermCondition;