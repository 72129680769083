import React, { useState } from 'react';
import './appservice.css';
import serleft from '../../assets/images/for-ser-left.png';
import slf2 from '../../assets/images/bg/slf2.png';
import { Button, Row, Col, Container, Nav, Tab, Image } from 'react-bootstrap';
import Vendors from './servendors';
import Clients from './serclient';
import { useClassToggle } from '../context';
const AppService = (service) => {
    const { isToggled, toggleClass } = useClassToggle();
    const classnew = `py-5 appService back-color-white ${isToggled ? 'dark' : 'light'}  + ${service.className}`;
	return (
        
       <>
           <section className={classnew}>
                <span className='counter'>04</span>
                <Container>
                    <Row>
                        <Col lg="12" className='appService-tab'>
                            <Tab.Container id="left-tabs-example " defaultActiveKey="Vendors">
                                <Row>
                                    <Col lg={12}>
                                        <Nav variant="pills" className="flex-row justify-content-center">
                                            <Nav.Item>
                                            <Nav.Link eventKey="Vendors">Vendors</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="Clients">Clients</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} className='min-tab-contain'>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="Vendors">
                                                <Vendors />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="Clients">
                                                <Clients />
                                            </Tab.Pane>
                                            <h2 className="subtitle text-color-gray"><span>A Flexible Transport </span> App Solution</h2>
                                            <Image src={serleft} alt="" className='ser-left' />
                                            <Image src={slf2} alt="" className='slf2' />
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </Col>
                    </Row>
                </Container>
           </section>
       </>

	);
};

export default AppService;