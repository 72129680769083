import React from "react";
import './flexibleTransportVendor.css';
import { Container, Row, Image, Button, Col, Form } from "react-bootstrap";
import image  from '../../../assets/images/productpage/pro1.png';
import { useClassToggle } from "../../context";
const FlexibleTransportVendor = () => {
    const { isToggled, toggleClass } = useClassToggle();
	return (
        
		<>
            <section className={`flexibleTransportVendor py-5 ${isToggled ? 'dark' : 'light'}`}>
                <Container>
                    <Row>
                        <Col lg="12" className="mb-5">
                            <h2 className="subtitle text-color-gray text-center">  <span>A Flexible Transport</span> Vendor Portal</h2>
                        </Col>
                        <Col lg="12 text-center ftv-img">
                            <Image src={image} alt="" />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
	);
};

export default FlexibleTransportVendor;