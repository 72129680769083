import webApi from '../../WebApi/WebApi';

//Client Faqs list
export const getAllFaqClientList = async () => {
    return await webApi.get(`/getFaqsByFeatures?type=user`);
}

//Vendor Faqs list
export const getAllFaqVendorList = async () => {
    return await webApi.get(`/getFaqsByFeatures?type=vendor`);
}