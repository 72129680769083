import axios from "axios";
import CryptoJS from "crypto-js";

const prodApi =  "https://nodeserver.mydevfactory.com:5000/api/v1" 

const devApi = "https://nodeserver.mydevfactory.com:5000/api/v1"
// const devApi = "http://localhost:9000/api/web"

export let baseURL;
const subdomain=window.location.host.split(".")[0];

if (process.env.NODE_ENV === "production" && subdomain==="live") {
  baseURL = prodApi;
} else {
  baseURL = devApi; //prodApi //
}

function calculateChecksum(data) {
  // Convert the JavaScript object or string to JSON string
  const jsonData = JSON.stringify(data);
  console.log("STRINGYFY DATA==", jsonData);

  // Create a SHA-256 hash
  const checksum = CryptoJS.SHA256(jsonData).toString(CryptoJS.enc.Hex);

  return checksum;
}


//console.log(process.env.NODE_ENV);

let instance = axios.create({
  baseURL: baseURL,
  // baseURL:betaApi,
  responseType: "json",
});

instance.defaults.headers.common["Authorization"] = localStorage.getItem(
  "ff_AuthToken"
);
instance.interceptors.request.use(config => {

  // Calculate checksum for the request data
  const checksum = calculateChecksum(config.data);
  // Attach checksum to headers
  config.headers['X-Checksum'] = checksum;
  return config;
}, error => {
  return Promise.reject(error);
});

export default instance;
