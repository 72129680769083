import React, { useState } from 'react';
import { Container, Row, Image, Button, Col, Nav, Tab} from "react-bootstrap";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
const Client = ({cItem}) => {
   
    const ClientOptions = {
        loop: true,
        margin:15,
        nav:true,
        navText: ["<span class='left-arrow'></span>", "<span class='right-arrow'></span>"],
        dots:false,
        responsive:{
            0: {
                items: 1
            },
            480: {
                items: 2
            },
            768: {
                items: 3,
                loop: false,
                margin:20,
            },
            1200: {
                items: 4,
                loop: false,
                margin:30
            }
        }
    };
    
	return (
        
        <OwlCarousel className='owl-theme app-feature-list' {...ClientOptions}>
            {cItem.map((item, index) => (
                <div className='item' key={index}>
                <div className='app-feature-item'>
                    <Image alt='' src={item.image} />
                    <h3>
                    {item.title}
                    </h3>
                    <p>
                    {item.description}
                    </p>
                </div>
                </div>
            ))}
           
    </OwlCarousel>

	);
};

export default Client;