import React from "react";
import { Image } from "react-bootstrap";
const ServiceList = ({serviceList})=>{
   const data = serviceList[0].Services;
   console.log("data", data)
    return(
        <>
           
                <ul className="serviceslist">
                {data.map((service, index) => ( 
                    <li key={index} className={index === 0 ? 'active' : ''}>
                        <div className="srl-icon">
                            <Image alt="" src={service.serviceicon} />
                        </div>
                        <div className="srl-cont">
                            <h2 className="text-color-gray">{service.title}</h2>
                            <p>{service.info}</p>
                        </div>
                    </li>
                   ))}
                    
                </ul>
            
            
        </>
    )
} 

export default ServiceList;