import React from "react";
import './innerbanner.css'
import Innerbreadcrumb from "../innerbreadcrumb";
const InnerBanner = (props) => {
    const classes = 'banner-wrapper inner-banner ' + props.className;
    const innerbanner = props.style;
	return (
		<section className={classes} style={innerbanner}> 
            <div className="banner-container min-height-450  d-flex align-items-center justify-content-center w-100">
               <h2>
                    {props.children}
                </h2> 
            </div>
		</section>
	);
};

export default InnerBanner;