import React from "react";
import InnerBanner from "../../components/innerpagebanner";
import innerbannerimg from "../../assets/images/banner/inner-banner.jpg";
import Innerbreadcrumb from "../../components/innerbreadcrumb";
import './manual-handling.css';
import ManualHandlingConten from "./manual-handling-conten";
const ManualHandling = () => {
	return (
		<>
		<InnerBanner style={{ backgroundImage: `url(${innerbannerimg})` }}>
            <span>Manual for </span> Handling Client Complaints and Conflict Resolution
        </InnerBanner>
		<Innerbreadcrumb >Manual for Handling Client Complaints and Conflict Resolution</Innerbreadcrumb>
		<ManualHandlingConten />
		</>
	);
};

export default ManualHandling;