import React, { useState } from "react";
import "./appfeatures.css";
import { Container, Row, Image, Button, Col, Nav, Tab } from "react-bootstrap";
import icon1 from "../../assets/images/icon/af-icon1.svg";
import icon2 from "../../assets/images/icon/af-icon2.svg";
import icon3 from "../../assets/images/icon/af-icon3.svg";
import icon4 from "../../assets/images/icon/af-icon4.svg";
import Vendors from "./vendors";
import Client from "./Clients";
import { useClassToggle } from "../context";
const AppFeatures = () => {
  const { isToggled, toggleClass } = useClassToggle();
  const vItem = [
    {
      image: icon2,
      title: "Instant Booking",
      description: "Securely book transport services in real-time.",
    },
    {
      image: icon1,
      title: "Track Shipments",
      description: "Monitor your cargo every step of the way.",
    },
    {
      image: icon3,
      title: "Payment Integration",
      description: " Easily settle invoices and payments online.",
    },
    {
      image: icon4,
      title: "Rating and Review System",
      description: "Share your feedback for continuous improvement.",
    },
    {
      image: icon2,
      title: "Live Support",
      description:
        "Reach out to our dedicated carriers whenever you need assistance.",
    },
  ];
  const cItem = [
    {
      image: icon2,
      title: "Choice of Vendors",
      description: "Select your preferred vendors. Or try new ones.",
    },
    {
      image: icon1,
      title: "Multiple Quotes",
      description: "Seek quotes from multiple vendors at once.",
    },
    {
      image: icon3,
      title: "Easy Payments",
      description: " Payments secured and supported by Stripe.",
    },
    {
      image: icon4,
      title: "Booking Made Simple",
      description: "Easy booking process on our Xturra platform or App.",
    },
    {
      image: icon1,
      title: " Live Support",
      description:
        "Reach out to our dedicated carriers whenever you need assistance.",
    },
  ];
  return (
    <>
      <section
        className={`back-color-white app-features py-5 ${
          isToggled ? "dark" : "light"
        }`}
      >
        <Container>
          <Row>
            <Col lg="12">
              <Tab.Container id="left-tabs-example" defaultActiveKey="Vendors">
                <Row className="align-items-center">
                  <Col lg={4} md={5} sm={6}>
                    <h2 className="subtitle text-color-gray">
                      <span>On Demand Transport </span> App Features
                    </h2>
                    <p className="text-color-gray">
                      Our On-Demand Transport App is the heart of our platform,
                      offering unparalleled convenience. With it, you can:
                    </p>
                  </Col>
                  <Col lg={8} md={7} sm={6}>
                    <Nav
                      variant="pills"
                      className="flex-row app-features-tab-btn"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="Vendors">Vendors</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="Clients">Clients</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} className="pt-3">
                    <Tab.Content>
                      <Tab.Pane eventKey="Vendors">
                        <Vendors vItem={vItem} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="Clients">
                        <Client cItem={cItem} />
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AppFeatures;
