import webApi from "../../WebApi/WebApi";


// export const getreqForm = async (data) => {
//     console.log(data,"data")
//     return await webApi.post('/helpCenter/message', data); 
// } 

export const getreqForm = async (data,onSuccess,onFailure) => {
      console.log(data,"formData")
    try {
      const res = await webApi.post(       
        `/helpCenter/message`,data
       
      );
      if (res.status === 200) {
  
  
        const r = res.data;
        console.log(r, "rerere")
  
        return onSuccess(r);
  
      } else {
        onFailure("Something Wrong! Please Try again later" + res.data);
      }
    } catch (error) {
      console.log("fdgdf")
    }
  };