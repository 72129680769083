import React from "react";
const ServicemainContent = ({serviceContent})=>{
   
    return(
        <>
            <h2 className="subtitle text-color-gray"><span>{serviceContent[0].heading}</span></h2>
            <h2 className="subtitle text-color-gray mb-5">{serviceContent[0].title}</h2>
           
            <div className="text-color-gray" dangerouslySetInnerHTML={{ __html: serviceContent[0].Description }}></div>
            
        </>
    )
} 

export default ServicemainContent;