import React from "react";
import './flexibletransportclients.css';
import { Container, Row, Image, Button, Col, Form } from "react-bootstrap";
import image  from '../../../assets/images/productpage/pro2.png';
import { useClassToggle } from "../../context";
const FlexibletransportClients = () => {
    const { isToggled, toggleClass } = useClassToggle();
	return (
        
		<>
            <section className={`flexibletransportclients py-5 ${isToggled ? 'dark' : 'light'}`}>
                <Container>
                    <Row className="align-items-center">
                        <Col lg="6">
                            <Image src={image} alt="" />
                        </Col>
                        <Col lg="6">
                            <h2 className="subtitle text-color-gray">  <span>A Flexible Transport</span> Vendor Portal</h2>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
	);
};

export default FlexibletransportClients;