import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';
import { getAllBannerList } from '../../../Service/Banner/BannerManagementService';

const CustomCarousel = () => {
  const [bannerList, setBannerList] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setActiveIndex(selectedIndex);
  };

  useEffect(() => {
    getAllBanner();
  },[]);

  async function getAllBanner() {
    const response = await getAllBannerList();
    setBannerList(response?.data?.result?.data);
    // console.log("res", response?.data?.result?.data)
  }
  const scrollToTop = () => {
    window.scrollTo({
        top: 700,
        behavior: 'smooth', // for smooth scrolling
    });
};
  return (
    <>
      <section className='min-banner'>

        <Carousel activeIndex={activeIndex} onSelect={handleSelect} fade className='home-min-banner'>
          {bannerList.map((item, index) => (
            <Carousel.Item key={index}>
              <img
                className="d-block w-100"
                src={item.image_url}
                // alt={item.altText}
              />
              <Carousel.Caption>
                <h3>{item.title}</h3>
                {/* <p>{item.description}</p> */}
                <Link to="/">Get Started</Link>
              </Carousel.Caption>
            </Carousel.Item>
          ))}

        </Carousel>
        <ul activeIndex={activeIndex} className='banner-count'>
          {bannerList.map((item, index) => (
            <li className={index === activeIndex ? 'active' : ''}>{index + 1}</li>
          ))}
        </ul>
        <span className='blink-tab' onClick={scrollToTop}></span>

      </section>
    </>
  );
};

export default CustomCarousel;