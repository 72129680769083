import React, { useState, useEffect } from 'react';
import './appsolution.css';
import { Container, Row, Image, Button, Col, Nav, Tab } from "react-bootstrap";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import bg3 from '../../../assets/images/bg/slbg1.png';
import bg1 from '../../../assets/images/bg/slbg2.png';
import bg2 from '../../../assets/images/bg/slbg3.jpg';
import play from '../../../assets/images/icon/play-btn.svg';
import { useClassToggle } from '../../context';
import { getAllStoriesList } from '../../../Service/Stories/StoriesManagementService';

const AppSolution = () => {
    const [storiesList, setStoriesList] = useState([]);
    const { isToggled, toggleClass } = useClassToggle();
    const solutionSlider = {
        loop: true,
        nav: false,
        items: 2,
        dots: true,
        center: true,
        responsive: {
            0: {
                items: 1
            },
            480: {
                items: 1
            },
            768: {
                items: 2,
                loop: true,
                margin: 20,
            },
            1000: {
                items: 2.1,
                loop: true,
                margin: 30
            }
        }
    };
    const solutionItems = [
        {
            image: bg3,
            title: 'A Flexible Transport',
        },
        {
            image: bg2,
            title: 'A Flexible Transport',
        },
        {
            image: bg1,
            title: 'A Flexible Transport',
        },
        {
            image: bg2,
            title: 'A Flexible Transport',
        }
    ];

    useEffect(() => {
        getAllStories();
    }, []);

    async function getAllStories() {
        const response = await getAllStoriesList();
        setStoriesList(response?.data?.result?.data)
        // console.log("st", response?.data?.result?.data)
    }
    return (

        <>
            <section className={`back-color-white app-solution py-5 ${isToggled ? 'dark' : 'light'}`}>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <h2 className='subtitle text-color-gray text-center pb-3'>
                                <span>A Flexible Transport </span> App Solution
                            </h2>
                        </Col>
                        <Col lg={12} className='pt-4'>
                            {/* <OwlCarousel className='owl-theme  solution-slider' {...solutionSlider}>
                                {storiesList.map((item, index) => (
                                    <div className='item' key={index}>
                                        <div className='solution-item' >
                                            <video width="515px" height="450px" controls>
                                                <source src={item.video_url} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                             <Button><span>Watch the video</span> <Image alt='play btn' src={play} /></Button> 
                                        </div>
                                    </div>
                                ))}

                            </OwlCarousel> */}

                            {storiesList.length > 0 ? (
                                <OwlCarousel className='owl-theme solution-slider' {...solutionSlider}>
                                    {storiesList.map((item, index) => (
                                        <div className='item' key={index}>
                                            <div className='solution-item'>
                                                <video width="100%" height="100%" controls>
                                                    <source src={item?.video_url} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            </div>
                                        </div>
                                    ))}
                                </OwlCarousel>
                            ) : (
                                <p>Loading...</p>
                            )}
                        </Col>
                    </Row>
                </Container>
            </section>
        </>

    );
};

export default AppSolution;