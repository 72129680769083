import React from "react";
import './clientfeatures.css';
import { Container, Row, Image, Button, Col, Form, Card } from "react-bootstrap";
import icon  from '../../../assets/images/icon/af-icon1.svg';
import icon2  from '../../../assets/images/icon/af-icon2.svg';
import icon3  from '../../../assets/images/icon/af-icon3.svg';
import icon4  from '../../../assets/images/icon/af-icon4.svg';
import { useClassToggle } from "../../context";
const Clientfeatures = () => {
    const { isToggled, toggleClass } = useClassToggle();
	return (
        
		<>
            <section  className={` back-color-white clientfeatures py-5 ${isToggled ? 'dark' : 'light'}`}>
                <Container>
                    <Row className="align-items-center">
                       
                        <Col lg="12">
                            <h2 className="subtitle text-color-gray text-center">  <span>Which Features client </span> will get from portals</h2>
                            <p className="text-color-gray  text-center">Clients can expect the following features from our dedicated portals:</p>
                        </Col>
                    </Row>
                    <Row className="mt-5 Clientfeatures-list">
                        <Col lg={3} className="list-card">
                            <Card>
                                <Image src={icon} alt="" />
                                <h3>
                                Efficiency
                                </h3>
                                <p>
                                Streamlined transport management for enhanced productivity.
                                </p>
                            </Card>
                        </Col>
                        <Col lg={3} className="list-card">
                            <Card>
                                <Image src={icon2} alt="" />
                                <h3>
                                Transparency
                                </h3>
                                <p>
                                Access to real-time information and historical data.
                                </p>
                            </Card>
                        </Col>
                        <Col lg={3} className="list-card">
                            <Card>
                                <Image src={icon3} alt="" />
                                <h3>
                                Cost Control
                                </h3>
                                <p>
                                Plan your logistics budget with accurate pricing.
                                </p>
                            </Card>
                        </Col>
                        <Col lg={3} className="list-card">
                            <Card>
                                <Image src={icon4} alt="" />
                                <h3>
                                Reliability
                                </h3>
                                <p>
                                Trust in our network of reputable transport providers.
                                </p>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
	);
};

export default Clientfeatures;