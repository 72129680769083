import React from "react";
import InnerBanner from "../../components/innerpagebanner";
import innerbannerimg from "../../assets/images/banner/inner-banner.jpg";
import Innerbreadcrumb from "../../components/innerbreadcrumb";
import GetinTouch from "../../components/getintouch";
import './blog.css'
import BlogListing from "./bloglisting";
const Blog = () => {
	return (
		<>
			<InnerBanner style={{ backgroundImage: `url(${innerbannerimg})` }}>
                <span>The</span> Blog
				
            </InnerBanner>
			<Innerbreadcrumb >Blog</Innerbreadcrumb>
			<BlogListing />
			<GetinTouch className="GetinTouchblogpage" />
		</>
	);
};

export default Blog;