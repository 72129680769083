import React from "react";
import InnerBanner from "../../components/innerpagebanner";
import innerbannerimg from "../../assets/images/banner/inner-banner.jpg";
import Innerbreadcrumb from "../../components/innerbreadcrumb";
import './safety.css';
import SafetyConten from "./safety-conten";
const Safety = () => {
	return (
		<>
		<InnerBanner style={{ backgroundImage: `url(${innerbannerimg})` }}>
            <span>Safety </span> and OH&S Manual
        </InnerBanner>
		<Innerbreadcrumb >Safety and OH&S Manual</Innerbreadcrumb>
		<SafetyConten />
		</>
	);
};

export default Safety;