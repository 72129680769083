import React from "react";
import InnerBanner from "../../components/innerpagebanner";
import innerbannerimg from "../../assets/images/banner/contact-from-banner.jpg";
import Innerbreadcrumb from "../../components/innerbreadcrumb";
import GetinTouch from "../../components/getintouch";
import './contact.css';
const Contact = () => {
	return (
		<>
			<InnerBanner style={{ backgroundImage: `url(${innerbannerimg})` }}>
                <span>Contact</span>  Form
            </InnerBanner>
			<Innerbreadcrumb >Contact</Innerbreadcrumb>
			<GetinTouch className="getintuchpage"></GetinTouch>
		</>
	);
};

export default Contact;