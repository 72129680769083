import React, { useState, useEffect } from 'react';
import './header.css';
import logo from '../../assets/images/logo.png';
import menu from '../../assets/images/icon/menu.svg';
import search from '../../assets/images/icon/search.svg';
import { Container, Row, Image, Button, Col, Modal } from "react-bootstrap";
import { Link } from 'react-router-dom';

import Navemenu from './nave';
const Header = () => {
	const [navshow, setNavshow] = useState(false);
	const handleShow = () => setNavshow(!navshow);
	const handleClose = () => setNavshow(false);
	const [isBlack, setIsBlack] = useState(false);
		useEffect(() => {
			window.addEventListener('scroll', handleScroll);
			return () => {
			window.removeEventListener('scroll', handleScroll);
			};
		}, []);

		const handleScroll = () => {
			if (window.pageYOffset > 100) {
				setIsBlack(true);
			} else {
				setIsBlack(false);
			}
		};
		
	return (
		<>
			<header className={`top-header ${isBlack ? 'header-black' : ''}`}>
				<Container>
					<Row>
						<Col className='col-3'>
						<Link to="/"> <Image src={logo}  alt="Xturra" className="logo"/></Link>
						</Col>
						<Col className="text-end">
							<a href="https://nodeserver.mydevfactory.com/projects/deep/Xturra/xturra-vendor-web/#" className="login-register-btn btn active">Login</a>
      						<a href="https://nodeserver.mydevfactory.com/projects/deep/Xturra/xturra-vendor-web/#" className="login-register-btn btn">Register</a>
							{/* <Button className="search-button seme-btn"  ><Image src={search} alt="search" /></Button> */}
							{/* onClick={toggleClass} */}
							<Button  className="menu-button seme-btn"  onClick={handleShow}><Image src={menu} alt="toggle menu" /></Button>
						</Col>
					</Row>
				</Container>
			</header>
			
			<Navemenu navshow={navshow} setNavshow={setNavshow} />
		</>
	);
};

export default Header;