import React from "react";
import InnerBanner from "../../components/innerpagebanner";
import innerbannerimg from "../../assets/images/banner/inner-banner.jpg";
import Innerbreadcrumb from "../../components/innerbreadcrumb";
import './appropriate-language.css';
import AppropriateLanguageConten from "./appropriate-language-conten";
const AppropriateLanguage = () => {
	return (
		<>
		<InnerBanner style={{ backgroundImage: `url(${innerbannerimg})` }}>
            <span>Appropriate   </span>  Language and Attire Manual
        </InnerBanner>
		<Innerbreadcrumb >Appropriate Language and Attire Manual</Innerbreadcrumb>
		<AppropriateLanguageConten />
		</>
	);
};

export default AppropriateLanguage;