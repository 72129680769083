import React from "react";
import './faq.css';
import { Container, Row, Image, Button, Col, Accordion, Tab, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import TopicsVendors from "./topicsforvendors";
import TopicsClients from "./topicsforclients";
import { useClassToggle } from "../context";
const Faq = () => {
    const data = [
        {
            title:'Sign Up as Vendor',
            faqlist:[
                {
                    title:'Are there any additional fees for using the service ?',
                    description:`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,`
                },
                {
                    title:'Can I choose the carrier for my shipment ?',
                    description:`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,`
                },
                {
                    title:'Can I choose the carrier for my shipment ?',
                    description:`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,`
                },
            ]
        },
        {
            title:'Mobile Application related topics',
            faqlist:[
                {
                    title:'Are there any additional fees for using the service ?',
                    description:`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,`
                },
                {
                    title:'Can I choose the carrier for my shipment ?',
                    description:`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,`
                },
                {
                    title:'Can I choose the carrier for my shipment ?',
                    description:`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,`
                },
            ]
        },
        {
            title:'Payment and Account management topics',
            faqlist:[
                {
                    title:'Are there any additional fees for using the service ?',
                    description:`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,`
                },
                {
                    title:'Can I choose the carrier for my shipment ?',
                    description:`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,`
                },
                {
                    title:'Can I choose the carrier for my shipment ?',
                    description:`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,`
                },
            ]
        },
        {
            title:'Support',
            faqlist:[
                {
                    title:'Are there any additional fees for using the service ?',
                    description:`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,`
                },
                {
                    title:'Can I choose the carrier for my shipment ?',
                    description:`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,`
                },
                {
                    title:'Can I choose the carrier for my shipment ?',
                    description:`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,`
                },
            ]
        },
        {
            title:'Other related topics',
            faqlist:[
                {
                    title:'Are there any additional fees for using the service ?',
                    description:`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,`
                },
                {
                    title:'Can I choose the carrier for my shipment ?',
                    description:`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,`
                },
                {
                    title:'Can I choose the carrier for my shipment ?',
                    description:`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,`
                },
                
            ]
        },
        
    ]
    const { isToggled, toggleClass } = useClassToggle();
	return (
        
		<>
            <section className={`back-color-white fagpage-min py-5 ${isToggled ? 'dark' : 'light'}`}>
                <Container>
                    <Row className='align-items-center'>
                        <Col lg="12">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="Topics-for-vendors">
                                <Row className="mb-5">
                                    <Col lg={4}>
                                        <h2 className="subtitle text-color-gray mb-3">
                                            <span>FAQ</span>
                                        </h2>
                                    </Col>
                                    <Col lg={8}>
                                    <Nav variant="pills" className="flex-Row faq-tab-btn">
                                        <Nav.Item>
                                        <Nav.Link eventKey="Topics-for-vendors">Topics for vendors</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                        <Nav.Link eventKey="Topics-for-clients">Topics for clients</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="Topics-for-vendors">
                                                <Row>
                                                    <Col lg="12" className="faq-accordion">
                                                        <TopicsVendors />
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="Topics-for-clients">
                                                <Row>
                                                    <Col lg="12" className="faq-accordion">
                                                    <TopicsClients  />
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
	);
};

export default Faq;