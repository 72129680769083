import React from "react";
import "./footer.css";
import logo from "../../assets/images/logo.png";
import instagram from "../../assets/images/icon/instagram.svg";
import Frame from "../../assets/images/icon/Frame.svg";
import facebook from "../../assets/images/icon/facebook.svg";
import Youtube from "../../assets/images/icon/youtubeicon.png";
import Tiktok from "../../assets/images/icon/tiktakicon.png";
import { Link } from "react-router-dom";
import { Container, Row, Image, Button, Col } from "react-bootstrap";
import { animateScroll as scroll } from "react-scroll";
import ScrollToTopButton from "./scrolltotopbutton";
import { useClassToggle } from "../../components/context";
const Footer = () => {
  const { isToggled, toggleClass } = useClassToggle();

  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <>
      <ScrollToTopButton />
      <footer
        className={`back-color-white pt-5 ${isToggled ? "dark" : "light"}`}
      >
        <Container>
          <Row>
            <Col lg="12" className="text-center">
              <Link to="/">
                {" "}
                <Image src={logo} alt="Xturra" className="logo" />
              </Link>
            </Col>
            <Col lg="12" className="text-center socil-navlink pt-4 pb-2 ">
              <a
                href="https://www.instagram.com/xturra_?igsh=a3M1Ym9xMDljb2Ey&utm_source=qr"
                target="_blank"
              >
                <Image alt="" src={instagram} />
              </a>
              {/* <Link className="back-color-lgray" to="/">
                <Image alt="" src={instagram} />
              </Link> */}
              <a
                href="https://www.linkedin.com/company/xturra/"
                target="_blank"
              >
                <Image alt="" src={Frame} />
              </a>
              {/* <Link className="back-color-lgray" to="/">
                <Image alt="" src={Frame} />
              </Link> */}
              <a
                href="https://www.facebook.com/share/1GYkdHYCNM/?mibextid=wwXIfr"
                target="_blank"
              >
                <Image alt="" src={facebook} />
              </a>
              <a
                href="https://youtube.com/@xturra?si=Prj34eei2mvy2YL5"
                target="_blank"
              >
                <Image alt="" src={Youtube} width="28px" height="28px" />
              </a>
              <a
                href="https://www.tiktok.com/@xturra_?_t=ZS-8umRvWcPYXo&_r=1"
                target="_blank"
              >
                <Image alt="" src={Tiktok} width="28px" height="28px" />
              </a>
              {/* <Link className="back-color-lgray" to="/">
                <Image alt="" src={facebook} />
              </Link> */}
            </Col>
            <Col lg="12" className="text-center for-navlink py-2">
              {/* <Link className=" text-color-gray" to="/terms-and-conditions">Terms & Conditions</Link>
							<Link className=" text-color-gray" to="/privacy-policy">Privacy & Policies</Link>
							<Link className=" text-color-gray" to="/help-center">Help Centre</Link>
							<Link className=" text-color-gray" to="/blog">Blog</Link>
							<Link className=" text-color-gray" to="/contact">Contact</Link>
							<Link className=" text-color-gray" to="/">Business</Link>
							<Link className=" text-color-gray" to="/">Individuals</Link> */}
              <Link className=" text-color-gray" to="/terms-and-conditions">
                Terms and Conditions
              </Link>
              <Link className=" text-color-gray" to="/privacy-policy">
                Privacy Policy
              </Link>
              <Link className=" text-color-gray" to="/policy-of-service">
                Policy of Service
              </Link>
              <Link
                className=" text-color-gray"
                to="/policy-for-lost-or-damaged-items-or-goods"
              >
                Policy for Lost or Damaged Items or Goods
              </Link>
              <Link
                className=" text-color-gray"
                to="/illegal-prohibited-and-restricted-cargo"
              >
                Illegal, Prohibited and Restricted Cargo
              </Link>
              <Link
                className=" text-color-gray"
                to="/policy-on-transportable-goods-and-items"
              >
                Policy on Transportable Goods and Items
              </Link>
              <Link
                className=" text-color-gray"
                to="/anti-money-laundering-and-terrorist-financing-policy"
              >
                Anti-Money Laundering and Terrorist Financing Policy
              </Link>
              <Link className=" text-color-gray" to="/about">
                About
              </Link>
              <Link
                className=" text-color-gray"
                to="/manual-for-handling-client-complaints-and-conflict-resolution"
              >
                Manual for Handling Client Complaints and Conflict Resolution
              </Link>
              <Link className=" text-color-gray" to="/safety-and-ohs-manual">
                Safety and OH&S Manual
              </Link>
              <Link
                className=" text-color-gray"
                to="/appropriate-language-and-attire-manual"
              >
                Appropriate Language and Attire Manual
              </Link>
              <Link
                className=" text-color-gray"
                to="/prohibition-of-recreational-substances-manual"
              >
                Prohibition of Recreational Substances Manual
              </Link>
              <Link className=" text-color-gray" to="/refund-policy">
                Refund Policy
              </Link>
            </Col>
            <Col lg="12">
              <div className="copy-right-text w-100 text-center">
                <p className="text-color-gray">
                  2025 Xturra. All Rights Reserved
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
