import React from "react";
import InnerBanner from "../../components/innerpagebanner";
import innerbannerimg from "../../assets/images/banner/inner-banner.jpg";
import Innerbreadcrumb from "../../components/innerbreadcrumb";
import './refund-policy.css';
import RefundPolicyConten from "./refund-policy-conten";
const RefundPolicy = () => {
	return (
		<>
		<InnerBanner style={{ backgroundImage: `url(${innerbannerimg})` }}>
            <span>Refund </span> Policy
        </InnerBanner>
		<Innerbreadcrumb >Refund Policy</Innerbreadcrumb>
		<RefundPolicyConten />
		</>
	);
};

export default RefundPolicy;