import React from "react";
import InnerBanner from "../../components/innerpagebanner";
import innerbannerimg from "../../assets/images/banner/inner-banner.jpg";
import Innerbreadcrumb from "../../components/innerbreadcrumb";
import './about.css';
import AboutConten from "./about-conten";
const About = () => {
	return (
		<>
		<InnerBanner style={{ backgroundImage: `url(${innerbannerimg})` }}>
            <span>About </span>  
        </InnerBanner>
		<Innerbreadcrumb >About</Innerbreadcrumb>
		<AboutConten />
		</>
	);
};

export default About;