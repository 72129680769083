import React from "react";
import InnerBanner from "../../components/innerpagebanner";
import innerbannerimg from "../../assets/images/banner/inner-banner.jpg";
import Innerbreadcrumb from "../../components/innerbreadcrumb";
import './policy-lost.css';
import PolicyLostConten from "./policy-lost-conten";
const PolicyLost = () => {
	return (
		<>
		<InnerBanner style={{ backgroundImage: `url(${innerbannerimg})` }}>
            <span>Policy </span> for Lost or Damaged Items or Goods
        </InnerBanner>
		<Innerbreadcrumb >Policy for Lost or Damaged Items or Goods</Innerbreadcrumb>
		<PolicyLostConten />
		</>
	);
};

export default PolicyLost;