import React from "react";
import InnerBanner from "../../components/innerpagebanner";
import innerbannerimg from "../../assets/images/banner/inner-banner.jpg";
import Innerbreadcrumb from "../../components/innerbreadcrumb";
import AppFeatures from "../../components/appfeatures";
import Download from "../../components/download";
import GetinTouch from "../../components/getintouch";
import FlexibleTransportVendor from "../../components/forproduct/flexibletransportvendor";
import WhyvendorChoose from "../../components/forproduct/whyvendorchoose";
import FlexibletransportClients from "../../components/forproduct/flexibletransportclients";
import Clientfeatures from "../../components/forproduct/clientfeatures";
const Product = () => {
	return (
		<>
		<InnerBanner style={{ backgroundImage: `url(${innerbannerimg})` }}>
            <span>The </span>   Product
        </InnerBanner>
		<Innerbreadcrumb >Product</Innerbreadcrumb>
		<AppFeatures />
		<FlexibleTransportVendor />
		<WhyvendorChoose />
		<FlexibletransportClients />
		<Clientfeatures />
		<Download />
		<GetinTouch className="gt-product" />
		
		</>
	);
};

export default Product;