import React, { createContext, useContext, useState } from 'react';
const ClassToggleContext = createContext();
export function useClassToggle() {
  return useContext(ClassToggleContext);
}
export function ClassToggleProvider({ children }) {
  const [isToggled, setIsToggled] = useState(true);
  const toggleClass = () => {
    setIsToggled(!isToggled);
  };
  return (
    <ClassToggleContext.Provider value={{ isToggled, toggleClass }}>
      {children}
    </ClassToggleContext.Provider>
  );
}