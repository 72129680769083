import React, { useState, useEffect } from "react";
import './getintouch.css';
import { Container, Row, Image, Button, Col, Form } from "react-bootstrap";
import image from '../../assets/images/getintuch.png';
import { useClassToggle } from "../context";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getCreateMessage } from './getinTouchService';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom'

const GetinTouch = (props) => {
    const navigate = useNavigate();
    const { isToggled, toggleClass } = useClassToggle();
    const classes = `getinTouch-app py-5 ${isToggled ? 'dark' : 'light'}   ${props.className}`;
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [message, setMessage] = React.useState("");


    const onName = (e) => {
        setName(e.target.value)
    }

    const onEmail = (e) => {
        setEmail(e.target.value)

    }

    const onMessage = (e) => {
        setMessage(e.target.value)

    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (name === "") {
            console.log("hgfdadf")

            toast.error("Please type your name")
        } else if (email === "") {
            toast.error("Please type your email")

        } else if (message === "") {
            toast.error("Please type your message")

        } else {
            getCreateMessage(email, name, message,
                (r) => {
                    console.log(r, "response")
                    if (r.status === true) {
                        setName("")
                        setEmail("")
                        setMessage("")
                        // window.location.reload(true);
                        toast.success(r.message)

                        // navigate("/")

                    } else if (r.status === false) {
                        toast.error(r.errors.email)

                    }
                })
        }

    }
    return (

        <>

            <section className={classes}>
                <ToastContainer></ToastContainer>

                <span className='count-getinTouch'>60</span>
                <Image src={image} alt="" />
                <Container className="py-5">
                    <Row>
                        <Col lg-="12">
                            <h2 className="text-color-gray subtitle mb-5">
                                <span>Get  </span>in  touch
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg-="6">
                            <Form onSubmit={onSubmit}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Control type="text" placeholder="Enter name" value={name}
                                        onChange={onName} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Control type="email" placeholder="Enter email" value={email} onChange={onEmail} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Control as="textarea" placeholder="Type your message here" value={message} onChange={onMessage} />
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Form>
                        </Col>
                        <Col></Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default GetinTouch;