import React, { useState } from "react";
import asimg4 from "../../../assets/images/asimg4.jpeg";
import asimg5 from "../../../assets/images/asimg5.jpeg";
import asimg6 from "../../../assets/images/asimg6.jpeg";
import { Button, Row, Col, Container, Nav, Tab, Image } from "react-bootstrap";
const Clients = () => {
  return (
    <>
      <section className="appService-galleryimg">
        <div className="asglist">
          <Image src={asimg4} alt="" />
          <h4>Carriers</h4>
        </div>
        <div className="asglist">
          <Image src={asimg5} alt="" />
          <h4>Freight</h4>
        </div>
        <div className="asglist">
          <Image src={asimg6} alt="" />
          <h4>Logistics</h4>
        </div>
      </section>
    </>
  );
};

export default Clients;
