import React from "react";
import "./whyvendorchoose.css";
import {
  Container,
  Row,
  Image,
  Button,
  Col,
  Form,
  Card,
} from "react-bootstrap";
import icon1 from "../../../assets/images/icon/freight_Management.svg";
import icon2 from "../../../assets/images/icon/Organised_Bookings.svg";
import icon3 from "../../../assets/images/icon/Client_Management.svg";
import icon4 from "../../../assets/images/icon/freight_Management.svg";
import { useClassToggle } from "../../context";
const WhyvendorChoose = () => {
  const { isToggled, toggleClass } = useClassToggle();
  return (
    <>
      <section
        className={`whyvendorchoose py-5 ${isToggled ? "dark" : "light"}`}
      >
        <Container>
          <Row>
            <Col lg="4" className="mb-5">
              <h2 className="subtitle text-color-gray mt-150">
                <span>Why vendor will choose </span>Xturra
              </h2>
              <p className="text-color-gray">
                Welcome to Xturra, our cutting-edge platform where convenience
                meets efficiency in the world of transportation. Our product is
                designed to revolutionize the way businesses handle their
                logistics needs. With a user-friendly interface and a range of
                powerful features, we are here to simplify your transport
                management experience.
              </p>
              <button type="submit" class="btn btn-primary">
                Call us Now
              </button>
            </Col>
            <Col lg="8 text-center">
              <div className="Whychoose-list">
                <Card>
                  <Image src={icon1} alt="" />
                  <h3>Freight Management System</h3>
                  <p>Manage your operation with the Xturra system and apps</p>
                </Card>
                <Card>
                  <Image src={icon2} alt="" />
                  <h3>Organised Bookings</h3>
                  <p>
                    All bookings in one place, never loose your clients again
                  </p>
                </Card>
                <Card>
                  <Image src={icon3} alt="" />
                  <h3>Client Management</h3>
                  <p>
                    Send promotions and contact clients via Chat to discuss
                    future bookings
                  </p>
                </Card>
                <Card>
                  <Image src={icon4} alt="" />
                  <h3>Partner Onboarding</h3>
                  <p>
                    Easy Partner onboarding, making hiring simple and efficient
                  </p>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default WhyvendorChoose;
