import React, { useState, useEffect } from 'react';
import './scrolltotopbutton.css';
import gotop from '../../assets/images/icon/move-top.svg';
import { Image, Button } from "react-bootstrap";
const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', 
    });
  };
  return (
    <div className={`scroll-to-top ${isVisible ? 'visible' : 'd-none'}`}>
      <Button onClick={scrollToTop} className='dark scroll-btn'><Image src={gotop} alt="" /></Button>
    </div>
  );
};

export default ScrollToTopButton;