import React from "react";
import InnerBanner from "../../components/innerpagebanner";
import innerbannerimg from "../../assets/images/banner/inner-banner.jpg";
import Innerbreadcrumb from "../../components/innerbreadcrumb";
import './policy-service.css';
import PolicyServiceConten from "./policy-service-conten";
const PolicyService = () => {
	return (
		<>
		<InnerBanner style={{ backgroundImage: `url(${innerbannerimg})` }}>
            <span>Policy </span> Of Service
        </InnerBanner>
		<Innerbreadcrumb >Policy Of Service</Innerbreadcrumb>
		<PolicyServiceConten />
		</>
	);
};

export default PolicyService;