import React, { useState, useEffect } from "react";
import './requestform.css';
import { Container, Row, Image, Button, Col, Form } from "react-bootstrap";
import image from '../../assets/images/getintuch.png';
import { useClassToggle } from "../context";
import {getreqForm} from './requestformService'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const RequestForm = (props) => {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [subject, setSubject] = useState("")
    const [description, setDescription] = useState("")
    const [getImage, setGetImage] = useState(null)
    const [getImageresult, setGetImageresult] = useState("")






    const { isToggled, toggleClass } = useClassToggle();
    const classes = `requestform-app py-5 ${isToggled ? 'dark' : 'light'}   ${props.className}`;

    const onName = (e) => {
        setName(e.target.value)
    }
    const onEmail = (e) => {
        setEmail(e.target.value)

    }
    const onSubject = (e) => {
        setSubject(e.target.value)
    }
    const onDescription = (e) => {
        setDescription(e.target.value)

    }

    const onFile = (event) => {
        console.log(event.target.files[0], "filee")
        if (event["target"].files.length > 0) {
            const file = event["target"].files[0];
            // setUserData({...userData, profileImage : file});
            console.log(file, "101823")
            setGetImage(file)
            setGetImageresult(file.name)
            //     const reader = new FileReader();
            //     reader.readAsDataURL(file);
            //     reader.onload = (event) => {
            //         setGetImageresult(reader.result);
            //   };
        }

    }
    console.log(email,"email")

  

    const onSubmit =()=>{
        let formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('subject', subject);
        formData.append('description', description);
        formData.append('attachment', getImage);
        formData.append('role', "user");

        for (const value of formData.values()) {
            console.log(value,"valll");
          }
        console.log(formData.values(),"yessssss")
        if(name===""){
            toast.error("Please type your name")

        }else if(email===""){
            toast.error("Please type your email")

        }else if(subject===""){
            toast.error("Please type subject")

        }else if(description===""){
            toast.error("Please type description")

        }else if(!getImageresult){
            toast.error("Please upload a document")

        }
        else{        
       getreqForm(formData,
        (r) => {
            console.log(r, "response")
            if (r.status === true) {
                setName("")
                setEmail("")
                setSubject("")
                setDescription("")
                setGetImageresult("")
                // window.location.reload(true);
                toast.success(r.message)

                // navigate("/")

            } else if (r.status === false) {
                toast.error(r.errors.email)

            }
        })
    
    }
    }


    return (

        <>
            <section className={classes}>
            <ToastContainer></ToastContainer>

                <Image src={image} alt="" className="mt-5" />
                <Container className="py-5">
                    <Row>
                        <Col lg-="12">
                            <h2 className="text-color-gray subtitle mb-5">
                                <span>Submit  </span>a Request Form
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg-="6">
                            <Form>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Control type="text" placeholder="Name" onChange={onName} value={name} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Control type="email" placeholder="Email" onChange={onEmail} value={email}/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="Subject">
                                    <Form.Control type="text" placeholder="Subject" onChange={onSubject} value={subject}/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Control as="textarea" placeholder="Description" onChange={onDescription} value={description}/>
                                </Form.Group>
                                <Form.Group className="mb-3 Attachment" controlId="Attachment" value={getImage}>
                                    <Form.Label>{getImageresult}</Form.Label>
                                    

                                    <Form.Control type="file" placeholder="Attachment" onChange={onFile} accept="image/jpeg,.pdf,.doc,.txt" />
                                    <span>Choose File</span>
                                </Form.Group>
                                <Button variant="primary" type="button" onClick={onSubmit}>
                                    Submit
                                </Button>
                            </Form>
                        </Col>
                        <Col></Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default RequestForm;