import webApi from "../../WebApi/WebApi";

//Blog list
export const getAllBlogList = async () => {
  return await webApi.get(`/blog?page_size=70`);
};

//Blog details
export const getBlogDetailsList = async (blogId) => {
  return await webApi.get(`/blog/${blogId}`);
};
