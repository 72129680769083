import React, { useState, useEffect } from "react";
import blog1 from "../../assets/images/blog/blog17.png";
import blog2 from "../../assets/images/blog/blog16.png";
import blog3 from "../../assets/images/blog/blog4.png";
import blog5 from "../../assets/images/blog/blog15.png";
import blog6 from "../../assets/images/blog/blog5.png";
import blog7 from "../../assets/images/blog/blog6.png";
import blog8 from "../../assets/images/blog/blog7.png";
import blog9 from "../../assets/images/blog/blog8.png";
import blog10 from "../../assets/images/blog/blog9.png";
import blog11 from "../../assets/images/blog/blog4.png";
import blog13 from "../../assets/images/blog/blog11.png";
import blog12 from "../../assets/images/blog/blog12.png";
import { Container, Row, Col, Card, Image, Button } from "react-bootstrap";
import { useClassToggle } from "../../components/context";
import { getAllBlogList } from '../../Service/Blog/BlogManagementService';
import { format } from 'date-fns';
import { Link } from "react-router-dom";
const BlogListing = () => {
    const { isToggled, toggleClass } = useClassToggle();
    const [blogList, setBlogList] = useState([]);

    const [visibleBlogs, setVisibleBlogs] = useState(8);

    const handleViewMore = () => {
        setVisibleBlogs((prevVisibleBlogs) => prevVisibleBlogs + 8);
    };
    // const [visibleBlogs, setVisibleBlogs] = useState(8);

    // const handleScroll = () => {
    //     if (window.innerHeight + document.documentElement.scrollTop + 700 >= document.documentElement.scrollHeight) {
    //         setVisibleBlogs((prevVisibleBlogs) => prevVisibleBlogs + 8);
    //     }
    // };

    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll);
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll); 
    //     };
    // }, []);

    useEffect(() => {
        getAllBlog()
    }, [])

    async function getAllBlog() {
        const response = await getAllBlogList();
        setBlogList(response?.data?.result?.data);
        console.log("blog", response?.data?.result?.data)
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        return format(date, "dd MMM, yyyy");
    }
    return (
        <>
            <section className={`back-color-white bloglisting ${isToggled ? 'dark' : 'light'}`}>
                <Container>
                    <Row className="mb-5">
                        <Col lg="6">
                            <h2 className="subtitle text-color-gray mb-0">
                                <span>Latest</span>  News & Blog
                            </h2>
                        </Col>
                        <Col lg="6">
                            <p className="text-color-gray mb-0">
                                Our transportation app is designed to be flexible and adaptable, to meet the unique needs of each individual user or enterprise.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        {blogList.length > 0 ? (
                            blogList.slice(0, visibleBlogs).map((blog, index) => (
                                <Col lg="3" key={index}>
                                    <Link to="/blog-details" state={blog}>
                                        <Card
                                            className="blog-item mb-3"
                                            style={{ backgroundImage: `url(${blog?.image_url})` }}
                                        >
                                            <div className="blog-item-info">
                                                <h3>{blog?.title}</h3>
                                                <p className="oth-date">
                                                    <span><b>{formatDate(blog?.created_at)}</b></span>
                                                </p>
                                            </div>
                                        </Card>
                                    </Link>
                                </Col>
                            ))
                        ) : (
                            <p>No Blogs Found</p>
                        )}
                        <Col lg="12" className="text-center">
                            {visibleBlogs < blogList.length && (
                                <Button className="blog-btn" onClick={handleViewMore}>
                                    View More
                                </Button>
                            )}
                        </Col>
                        {/* <Col lg="5">
                            <Card className="blog-mid-item mb-3">
                                    <Image src={blog3}  alt="" />
                                    <h3>
                                        Lorem Ipsum is simply
                                    </h3>
                                    <p>
                                    Ritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas…
                                    </p>
                                    <p className="oth-date">
                                        <span>23 <b>Feb, 2023</b></span> <span>By, John Doe</span>
                                    </p>
                            </Card>
                            <Card className="news-list mb-3">
                                
                                <div className="news-contain pe-3">
                                    <small>
                                        Featured
                                    </small>
                                    <h3>
                                    The Positive Impact of Basic Healthy Yoga Routine
                                    </h3>
                                    <p  className="oth-date">
                                        <span>23 <b>Feb, 2023</b></span> <span>By, John Doe</span>
                                    </p>
                                </div>
                                <div className="news-list-img">
                                    <Image src={blog5} alt="" /> 
                                </div>
                            </Card>
                        </Col>
                        <Col lg="4" className="news-right">
                            <Card className="news-list mb-3">
                                
                                <div className="news-contain pe-3">
                                    <small>
                                    HIT
                                    </small>
                                    <h3>
                                    The Positive Impact of Basic Healthy Yoga Routine
                                    </h3>
                                    
                                </div>
                                <div className="news-list-img">
                                    <Image src={blog6} alt="" /> 
                                </div>
                            </Card>
                            <Card className="news-list mb-3">
                                
                                <div className="news-contain pe-3">
                                    <small>
                                        Featured
                                    </small>
                                    <h3>
                                    The Positive Impact of Basic Healthy Yoga Routine
                                    </h3>
                                    
                                </div>
                                <div className="news-list-img">
                                    <Image src={blog7} alt="" /> 
                                </div>
                            </Card>
                            <Card className="news-list mb-3">
                                
                                <div className="news-contain pe-3">
                                    <small>
                                    Topics
                                    </small>
                                    <h3>
                                    The Positive Impact of Basic Healthy Yoga Routine
                                    </h3>
                                    
                                </div>
                                <div className="news-list-img">
                                    <Image src={blog8} alt="" /> 
                                </div>
                            </Card>
                            <Card className="news-list mb-3">
                                
                                <div className="news-contain pe-3">
                                    <small>
                                    Topics
                                    </small>
                                    <h3>
                                    The Positive Impact of Basic Healthy Yoga Routine
                                    </h3>
                                   
                                </div>
                                <div className="news-list-img">
                                    <Image src={blog9} alt="" /> 
                                </div>
                            </Card>
                        </Col> */}

                    </Row>
                    {/* <Row className="mt-5">
                        <Col lg="6">
                            <Card className="bigblog blog-item mb-3" style={{ backgroundImage: `url(${blog10})` }}>
                                    <div className="blog-item-info p-4">
                                        <h2 className="subtitle">
                                        Plus-Size Models Do Not 
Support Obesity
                                        </h2>
                                        <p  className="oth-date">
                                            <span>23 <b>Feb, 2023</b></span> <span>By, John Doe</span>
                                        </p>
                                    </div>
                                </Card>
                        </Col>
                        <Col lg="6" className="botto-rightlist">
                            <Card className="news-list mb-3">
                                
                                
                                <div className="news-list-img">
                                    <Image src={blog11} alt="" /> 
                                </div>
                                <div className="news-contain ps-3">
                                    
                                    <h3>
                                        Lorem Ipsum simply dummy text of the printing industry.
                                    </h3>
                                    <p  className="oth-date">
                                            <span>23 <b>Feb, 2023</b></span> <span>By, John Doe</span>
                                        </p>
                                </div>
                            </Card>
                            <Card className="news-list mb-3">
                                
                                
                                <div className="news-list-img">
                                    <Image src={blog13} alt="" /> 
                                </div>
                                <div className="news-contain ps-3">
                                    
                                    <h3>
                                        Lorem Ipsum simply dummy text of the printing industry.
                                    </h3>
                                    <p  className="oth-date">
                                            <span>23 <b>Feb, 2023</b></span> <span>By, John Doe</span>
                                        </p>
                                </div>
                            </Card>
                            <Card className="news-list mb-3">
                                
                                
                                <div className="news-list-img">
                                    <Image src={blog12} alt="" /> 
                                </div>
                                <div className="news-contain ps-3">
                                    
                                    <h3>
                                        Lorem Ipsum simply dummy text of the printing industry.
                                    </h3>
                                    <p  className="oth-date">
                                            <span>23 <b>Feb, 2023</b></span> <span>By, John Doe</span>
                                        </p>
                                </div>
                            </Card>
                        </Col>
                    </Row> */}
                </Container>
            </section>
        </>
    );
};

export default BlogListing;