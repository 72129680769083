import React, { useState } from "react";
import "./discount.css";
import { Container, Row, Image, Button, Col, Nav, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import car from "../../assets/images/dis.png";
import { useClassToggle } from "../context";
const Discount = () => {
  const { isToggled, toggleClass } = useClassToggle();
  return (
    <>
      <section
        className={`back-Discount app-Discount ${isToggled ? "dark" : "light"}`}
      >
        <span className="count-down">90</span>
        <Container>
          <Row>
            <Col lg="12">
              <div className="Discountinfo">
                <h3>Get Vendor codes and discounts</h3>
                <p>
                  Our transport app offers a variety of features including
                  real-time tracking.
                </p>
                <Button
                  onClick={() =>
                    (window.location.href =
                      "https://nodeserver.mydevfactory.com/projects/deep/Xturra/xturra-vendor-web/#")
                  }
                >
                  Get Started
                </Button>
                <Image alt="" src={car} className="dis-car" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Discount;
