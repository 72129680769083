import React from "react";
import Banner from "../../components/home/banner";
import AppFeatures from "../../components/appfeatures";
import AppSolution from "../../components/home/appsolution";
import AppService from "../../components/appservice";
import HomeBlog from "../../components/home/homeblog";
import Discount from "../../components/discount";
import Download from "../../components/download";
import HomeFaq from "../../components/home/homefaq";
import GetinTouch from "../../components/getintouch";
const Home = () => {
	return (
		<>
			<Banner />
			<AppFeatures />
			<AppSolution />
			<AppService />
			<HomeBlog />
			<Discount />
			<Download />
			<HomeFaq />
			<GetinTouch />
		</>
	);
};

export default Home;