import React, { useState } from 'react';
import './banner.css';
import bannerimg from '../../../assets/images/banner/banner.png';
import CustomCarousel from "./customcarousel";
const Banner = () => {
    // const carouselItems = [
    //     {
    //     image: bannerimg,
    //       altText: 'Image 1',
    //       title: 'All Your Shipping Needs',
    //     },
    //     {
    //       image: bannerimg,
    //       altText: 'Image 2',
    //       title: 'All Your Shipping Needs 2',
    //     },
    //     {
    //       image: bannerimg,
    //       altText: 'Image 2',
    //       title: 'All Your Shipping Needs 3',
    //     },
    //   ];
    
	return (
        
       <>
        <CustomCarousel />
       </>

	);
};

export default Banner;