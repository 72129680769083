import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useClassToggle } from "../../components/context";
import { getAllTermsList } from '../../Service/Terms/TermsManagementService';
const TermConditionConten = () => {
    const { isToggled, toggleClass } = useClassToggle();
    const [loading, setLoading] = useState(false);
    const [termsList, setTermsList] = useState({});

    useEffect(() => {
        setLoading(true)
        getAllTerms()
    }, []);

    async function getAllTerms() {
        const result = await getAllTermsList();
        const response = result?.data;
        setTermsList(response.result);
        setLoading(false)

    }
    console.log("res", termsList);

        // Function to split content into paragraphs and render individually
        function renderContent(content) {
            if (!content) return <h2 className='text-center'>No Terms & Conditions Found</h2>;
            
            const paragraphs = content.split('<p>').map((paragraph, index) => (
                <div key={index}>
                    <p dangerouslySetInnerHTML={{ __html: paragraph.replace('</p>', '') }} />
                </div>
            ));
    
            return paragraphs;
        }

    return (
        <>
            <section className={`back-color-white min-contain ${isToggled ? 'dark' : 'light'}`}>
                <Container>
                    <Row>
                        <Col className="text-color-gray">
                            {
                                termsList ?
                                    <>
                                        <p className="mb-1">
                                            <b>
                                                {termsList?.name}
                                            </b>
                                        </p>
                                        <p className="mb-5">
                                        {renderContent(termsList?.content)}
                                        </p>
                                    </>
                                    : <h2 className='text-center'>No Terms & Conditions Found</h2>
                            }

                            {/* <p className="mb-1">
                            <b>
                            2. Timeframe for Requesting a Refund
                            </b>
                        </p>
                        <p className="mb-5">
                        Within two weeks of the date of delivery, users and businesses can request a refund for transportation services booked through the Marketplace. The user or business must get in touch with the vendor who provided the services in order to request a refund and provide the necessary information to confirm their identity and the transaction. All refunds are subject to terms and conditions and policies of the vendor. The vendor can agree or disagree to provide the refund, taking into account reasonable judicial legal and ethical considerations and also considering their local laws.
                        </p>
                        <p className="mb-1">
                            <b>
                            3. Refund Procedure
                            </b>
                        </p>
                        <p className="mb-5">
                        The vendor will process the refund through the payment gateway Stripe, which is used to book the transportation services, if the user or business is entitled to the refund. Depending on the payment gateway's terms and conditions, the refund will be credited to the user's or business's account. For terms and conditions of Stripe, please visit https://stripe.com/us/legal for more information.
                        </p>
                        <p className="mb-1">
                            <b>
                            4. Refund Policy Modifications
                            </b>
                        </p>
                        <p className="mb-5">
                        The Marketplace reserves the right to make any changes to this policy at any time and without prior notice. It is your duty to check this policy on a regular basis for any changes. If you continue to use the Marketplace or the Services following any changes, you will be deemed to have accepted the revised Policy.
                        </p>
                        <p className="mb-1">
                            <b>
                            5. Consultation and Communication with the Vendor
                            </b>
                        </p>
                        <p className="mb-5">
                        If the user or business still wishes to request a refund after the deadline has passed, they must consult and communicate with the vendor to agree on the new refund terms that the vendor and their policy of operation deem appropriate. If the parties are unable to come to an agreement that is acceptable to both parties, the user or business may take the matter to the Marketplace for further investigation and resolution.
                        </p> */}
                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    );
};

export default TermConditionConten;