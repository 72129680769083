import webApi from "../../WebApi/WebApi";


export const getCreateMessage = async (email,name,message,onSuccess,onFailure) => {
      
    try {
      const res = await webApi.post(
  
        `/contacts`,
        {
          email: email,
          name: name,
          message:message,
          role:"user"
          
        }
      );
      if (res.status === 200) {
  
  
        const r = res.data;
        console.log(r, "rerere")
  
        return onSuccess(r);
  
      } else {
        onFailure("Something Wrong! Please Try again later" + res.data);
      }
    } catch (error) {
      console.log("fdgdf")
    }
  };