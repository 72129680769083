import React from "react";
import InnerBanner from "../../components/innerpagebanner";
import innerbannerimg from "../../assets/images/banner/inner-banner.jpg";
import Innerbreadcrumb from "../../components/innerbreadcrumb";
import './illegal.css';
import IllegalConten from "./illegal-conten";
const Illegal = () => {
	return (
		<>
		<InnerBanner style={{ backgroundImage: `url(${innerbannerimg})` }}>
            <span>Illegal,  </span>  Prohibited and Restricted Cargo
        </InnerBanner>
		<Innerbreadcrumb >Illegal, Prohibited and Restricted Cargo</Innerbreadcrumb>
		<IllegalConten />
		</>
	);
};

export default Illegal;